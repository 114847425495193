// routeManager.js

let previousRoute = null;

export default {
  setPreviousRoute(route) {
    previousRoute = route;
  },
  getPreviousRoute() {
    return previousRoute;
  }
};
