<template>
<!--   -->
	<!-- Layout Header ( Navbar ) -->
	<a-layout-header class="py-0 my-0 px-0" :style="windowWidth > 991 ? 'background-color: #FFFFFF; padding-left: 40px !important; padding-right: 40px !important;' : 'background-color: #734EBF'" style="z-index: 1000; position:fixed; width: 100% !important; ">
		<!-- <div> -->
		
			<div class="header-col header-brand my-0 py-0" style="">
				<h6 class="pl-20" style="font-size: 18px; color: #FFFFFF; font-weight: 500px;">
					<img src="../../../public/images/icon_2.png" alt="" width="70px" height="60px" style="" class="px-0" v-if="windowWidth <= 991"> 
					<img src="../../../public/images/logo.png" alt="" width="180px" height="70px" style="" class="px-0" v-if="windowWidth > 991"> 
					<span style="position: absolute; left: 75px; top: 15px; bottom: 0px;" v-if="windowWidth <= 991">SILABU</span>
				</h6>
				<!-- Trigger Button For Navigation Menu For Small Screens -->
				<!-- <span class="ml-auto text-white" style="font-size: 12px;">
					<font-awesome-icon icon="fa-brands fa-whatsapp" class="text-white" style="font-size: 14px"/>
					Chat with us
				</span> -->
				<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger ">
					<font-awesome-icon icon="fa-solid fa-bars" class="text-white" style="display: block; font-size: 20px"/>
				</a-button>
				<!-- Trigger Button For Navigation Menu For Small Screens -->

			</div>
			<div class="header-col"></div>
			<div class="header-col"></div>
			<div class="header-col header-nav">
				<!-- Navigation Menu For Large Screens -->
				<a-menu v-model="currentRoute" mode="horizontal" theme="light" class="menu-large pr-20">
					<a-menu-item key="home" @click="navigateTo('home')">
						<router-link to="/#home" class="nav-link">
							Home
						</router-link>
					</a-menu-item>
					<a-menu-item key="student" @click="navigateTo('student')">
						<router-link to="/#student" class="nav-link">
							Student
						</router-link>
					</a-menu-item>
					<a-menu-item key="parent" @click="navigateTo('parent')">
						<router-link to="/#parent" class="nav-link">
							Parent
						</router-link>
					</a-menu-item>
					<a-menu-item key="teacher" @click="navigateTo('teacher')">
						<router-link to="/#teacher" class="nav-link">
							Teacher
						</router-link>
					</a-menu-item>
					<a-menu-item key="school" @click="navigateTo('school')">
						<router-link to="/#school" class="nav-link">
							School
						</router-link>
					</a-menu-item>
					<!-- <a-menu-item key="blogs">
						<router-link to="/blogs" class="nav-link" @click="e => e.preventDefault()">
							Blogs
						</router-link>
					</a-menu-item> -->
					<a-menu-item>
						<a-divider type="vertical" style="height: 40px; margin-bottom: 10px; background-color: #B5B4B4" />
					</a-menu-item>
					<a-menu-item key="signup">
						<router-link to="/sign-up" class="nav-link" @click="e => e.preventDefault()">
							<a-button id="btnBordered" type="text" class="px-20 mt-10" style="height: 35px; border-radius: 20px;">
								Create Account
							</a-button>
						</router-link>
					</a-menu-item>
					<a-menu-item key="signin">
						<router-link to="/sign-in" class="nav-link" @click="e => e.preventDefault()">
							<a-button id="btnBordered" type="text" class="px-20 mt-15" style="height: 35px; border-radius: 20px;">
								Sign In
							</a-button>
						</router-link>
					</a-menu-item>
				</a-menu>
				<!-- / Navigation Menu For Large Screens -->

				<!-- Collapsible Navigation Menu For Small Screens -->
				<div class="menu-small">
					
					<!-- Collapsible Component For Navigation Menu For Small Screens -->
					<a-collapse v-model="collapseNav" accordion>
						<a-collapse-panel key="1">

							<!-- Navigation Menu For Small Screens -->
							<a-menu mode="vertical">
								<a-menu-item key="home" @click="navigateTo('mobHome')">
									<router-link to="/#home" class="nav-link">
										Home
									</router-link>
								</a-menu-item>
								<a-menu-item key="student" @click="navigateTo('mobStudent')">
									<router-link to="/#student" class="nav-link">
										Student
									</router-link>
								</a-menu-item>
								<a-menu-item key="parent" @click="navigateTo('mobParent')">
									<router-link to="/#parent" class="nav-link">
										Parent
									</router-link>
								</a-menu-item>
								<a-menu-item key="teacher" @click="navigateTo('mobTeacher')">
									<router-link to="/#teacher" class="nav-link">
										Teacher
									</router-link>
								</a-menu-item>
								<a-menu-item key="school" @click="navigateTo('mobSchool')">
									<router-link to="/#school" class="nav-link">
										School
									</router-link>
								</a-menu-item>
								<!-- <a-menu-item key="blogs">
									<router-link to="/blogs" class="nav-link" @click="e => e.preventDefault()">
										Blogs
									</router-link>
								</a-menu-item> -->
								<a-menu-item>
									<router-link to="/sign-in" class="nav-link" @click="e => e.preventDefault()">
										<a-button id="btnBordered" type="text" class="px-20 mt-10" style="height: 35px; border-radius: 20px;">
											<span class="">Create Account</span>
										</a-button>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/sign-in" class="nav-link" @click="e => e.preventDefault()">
										<a-button id="btnBordered" type="text" class="px-20 mt-15" style="height: 35px; border-radius: 20px;">
											<span class="">Sign In</span>
										</a-button>
									</router-link>
								</a-menu-item>
							</a-menu>
							<!-- / Navigation Menu For Small Screens -->

						</a-collapse-panel>
					</a-collapse>
					<!-- / Collapsible Component For Navigation Menu For Small Screens -->

				</div>
				<!-- / Collapsible Navigation Menu For Small Screens -->
				
			</div>
			<!-- <div class="header-col header-btn">
				<a-button href="https://www.creative-tim.com/product/muse-vue-ant-design-dashboard" target="_blank" type="primary" shape="round">FREE DOWNLOAD</a-button>
			</div> -->
		<!-- </div> -->
	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->

</template>

<script>

	export default ({
		data() {
			return {
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				windowWidth: window.innerWidth,
				collapseNav: 0,
				currentRoute: null,
			}
		},
		mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
		methods: {  
			onResize() {
				this.windowWidth = window.innerWidth
			},

			navigateTo(refName) {
				this.$root.$refs.HomePage.navigateTo(refName);
			}
		}
	})

</script>

<style lang="scss" scoped>

	
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

	#card {
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.7);

	}
</style>