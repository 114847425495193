<template>

	<!-- Layout Header ( Navbar ) -->
	<a-layout-header class="py-0 my-0 px-0" :style="windowWidth > 991 ? 'background-color: #FFFFFF; padding-left: 40px !important; padding-right: 40px !important;' : 'background-color: #734EBF'" style="z-index: 1000; width: 100% !important; ">
		<!-- <div> -->
		
			<div class="header-col header-brand my-0 py-0" style="">
				<h6 class="pl-20" style="font-size: 18px; color: #FFFFFF; font-weight: 500px;">
					<img src="../../../public/images/icon_2.png" alt="" width="70px" height="60px" style="" class="px-0" v-if="windowWidth <= 991"> 
					<img src="../../../public/images/logo.png" alt="" width="180px" height="70px" style="" class="px-0" v-if="windowWidth > 991"> 
					<span style="position: absolute; left: 75px; top: 15px; bottom: 0px;" v-if="windowWidth <= 991">SILABU</span>
				</h6>
				<!-- Trigger Button For Navigation Menu For Small Screens -->
				
				<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger ">
					<font-awesome-icon icon="fa-solid fa-bars" class="text-white" style="display: block; font-size: 20px"/>
				</a-button>
				<!-- Trigger Button For Navigation Menu For Small Screens -->

			</div>
			<!-- <div class="header-col"></div>
			<div class="header-col"></div> -->
			<div class="header-col header-nav ml-auto">
				<!-- Navigation Menu For Large Screens -->
				<a-menu v-model="currentRoute" mode="horizontal" theme="light" class="menu-large pr-20" style="">
					<a-menu-item class="mr-5 text-center">
						<router-link to="/home" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-house" class="mr-5" style=""/>
							<span class="py-0 my-0" style="">Home</span>
						</router-link>
					</a-menu-item>
					<a-menu-item class="mr-5" v-if="role.code == 'teacher' || role.code == 'student'">
						<router-link to="/institutions-list" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-graduation-cap" class="mr-5" style=""/>
							<span class="">Institutions</span>
						</router-link>
					</a-menu-item>
					<a-menu-item class="mr-5" v-if="role.code == 'teacher' || role.code == 'student'">
						<router-link to="/classes" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-graduation-cap" class="mr-5" style=""/>
							<span class="">Classes</span>
						</router-link>
					</a-menu-item>
					<a-menu-item v-if="role.code == 'teacher'" class="mr-5">
						<router-link to="/tutoring-jobs" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-users" class="mr-5" style=""/>
							<span class="">Tutoring Jobs</span>
						</router-link>
					</a-menu-item>
					<a-menu-item v-if="role.code == 'student' || role.code == 'admin' && role.category == 'institute'" class="mr-5">
						<router-link :to="role.code == 'admin' ? '/institutions/teachers' : '/tutors'" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-user" class="mr-5" style=""/>
							<span class="">Tutors</span>
						</router-link>
					</a-menu-item>
					<a-menu-item class="mr-5">
						<router-link to="/calendar" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-calendar" class="mr-5" style=""/>
							<span class="">Schedule</span>
						</router-link>
					</a-menu-item>
					<a-menu-item class="mr-5">
						<router-link to="/notifications" class="nav-link" @click="e => e.preventDefault()">
							<font-awesome-icon icon="fa-solid fa-bell" class="mr-5" style=""/>
							<span class="">Notifications</span>
						</router-link>
					</a-menu-item>
					<a-menu-item class="mr-10" v-if="($route.name == 'Home' && role.code == 'student') ||  $route.name == 'Tutors' ||  $route.name == 'Classes' || $route.name == 'Notes' || $route.name == 'Quizzes'">
						<div class="mb-5" @click="launchSearchFilter">
							<font-awesome-icon icon="fa-solid fa-search" class="mr-5" style=""/>
							<span class="">Search</span>
						</div>
					</a-menu-item>
					<a-menu-item>
						<a-divider type="vertical" style="height: 40px; margin-bottom: 7px; background-color: #B5B4B4" />
					</a-menu-item>
					<a-menu-item key="signup">
						<a-dropdown type="link" class="" style="text-align: center !important">
					
							<a-menu slot="overlay" class="bg-white">
								<a-menu-item key="1" @click="toProfilePage"> <a-icon type="user" theme="outlined" /> Profile </a-menu-item>
								<a-menu-item key="2" @click="handleLogout"> <a-icon type="logout" theme="outlined" /> Logout </a-menu-item>
							</a-menu>
							<span style="padding-bottom: 0px;" class="text-center">
								<a-avatar :src="userDetails.profile != null ? userDetails.profile : ''" class="ml-5 mr-5 mb-10 text-center"/>
								<span class="" style="color: #414141; margin-bottom: 100px !important;"><strong>{{ userDetails.firstName }} {{ userDetails.lastName }}</strong></span>
							</span>
						
						</a-dropdown>
					</a-menu-item>
				</a-menu>


				<!-- / Navigation Menu For Large Screens -->

				<!-- Collapsible Navigation Menu For Small Screens -->
				<div class="menu-small">
					
					<!-- Collapsible Component For Navigation Menu For Small Screens -->
					<a-collapse v-model="collapseNav" accordion>
						<a-collapse-panel key="1">

							<!-- Navigation Menu For Small Screens -->
							<a-menu mode="vertical">
								<a-menu-item class="mr-5">
									<router-link to="/home" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-house" class="mr-10" style=""/>
										<span class="py-0 my-0" style="">Home</span>
									</router-link>
								</a-menu-item>
								<a-menu-item class="mr-5" v-if="role.code == 'teacher' || role.code == 'student'">
									<router-link to="/classes" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-graduation-cap" class="mr-10" style=""/>
										<span class="">Classes</span>
									</router-link>
								</a-menu-item>
								<a-menu-item v-if="role.code == 'teacher'" class="mr-5">
									<router-link to="/tutoring-jobs" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-users" class="mr-10" style=""/>
										<span class="">Tutoring Jobs</span>
									</router-link>
								</a-menu-item>
								<a-menu-item v-if="role.code == 'student' || role.code == 'admin' && role.category == 'institute'" class="mr-5">
									<router-link to="/tutors" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-user" class="mr-10" style=""/>
										<span class="">Tutors</span>
									</router-link>
								</a-menu-item>
								<a-menu-item class="mr-5">
									<router-link to="/calendar" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-calendar" class="mr-10" style=""/>
										<span class="">Schedule</span>
									</router-link>
								</a-menu-item>
								<a-menu-item class="mr-5">
									<router-link to="/notifications" class="nav-link" @click="e => e.preventDefault()">
										<font-awesome-icon icon="fa-solid fa-bell" class="mr-10" style=""/>
										<span class="">Notifications</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<a-divider type="horizontal" style="height: 0.5px; margin-bottom: 10px; background-color: #B5B4B4" />
								</a-menu-item>
								<a-menu-item key="signup">
									<a-dropdown type="link" class="" style="text-align: center !important">
								
										<a-menu slot="overlay" class="bg-white">
											<a-menu-item key="1" @click="toProfilePage"> <a-icon type="user" theme="outlined" /> Profile </a-menu-item>
											<a-menu-item key="2" @click="handleLogout"> <a-icon type="logout" theme="outlined" /> Logout </a-menu-item>
										</a-menu>
										<span style="padding-bottom: 0px;" class="text-center">
											<a-avatar :src="userDetails.profile != null ? userDetails.profile : ''" class="ml-5 mr-10"/>
											<span class="" style=""><strong>{{ userDetails.firstName }} {{ userDetails.lastName }}</strong></span>
										</span>
									
									</a-dropdown>
								</a-menu-item>
							</a-menu>
							<!-- / Navigation Menu For Small Screens -->

						</a-collapse-panel>
					</a-collapse>
					<!-- / Collapsible Component For Navigation Menu For Small Screens -->

				</div>
				<!-- / Collapsible Navigation Menu For Small Screens -->
				
			</div>
			<!-- <div class="header-col header-btn">
				<a-button href="https://www.creative-tim.com/product/muse-vue-ant-design-dashboard" target="_blank" type="primary" shape="round">FREE DOWNLOAD</a-button>
			</div> -->
		<!-- </div> -->

		<a-modal :footer="null" :header="null" :closable="false">

			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <a-input-search
						v-model="filter.search"
						placeholder="Search...."
						size="large"
						@search="submitSearchFilter"
						style="width: 90%"/>
					<a-button id="textCloseBtn" type="text" class="text-danger" v-if="filter.search.length > 0" @click="clearSearch">
						<font-awesome-icon icon="fa-solid fa-close" class="" style=""/>
					</a-button>
                </a-col>
            </a-row>

            
            <a-row :gutter="[24, 24]" class="mt-20 pt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 16px; color: #8C8C8C !important;" class="mb-5 pb-0">Refine results</h6>

					<hr style="border: 0.5px solid #E4E4E4 !important;"/>
                </a-col>
            </a-row>

            <a-row :gutter="[24, 24]" class="mt-15 mb-0 pb-0" v-if="$route.name != 'Tutors'">
                <a-col :span="24" class="">
                    <label class="" style="color: #606174 !important;"><strong style="color: #606174 !important;">Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'" class="">Classes</a-radio><br/>
                        <a-radio :value="'notes'" class="mt-5">Notes</a-radio><br/>
                        <a-radio :value="'quizzes'" class="mt-5">Quizzes</a-radio><br/>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutter="[24, 24]" class="mt-15 mb-0 pb-0" v-if="$route.name != 'Tutors'">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-5 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-5 mb-0 pb-0" v-if="$route.name != 'Tutors'">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'" class="">Starting Today</a-radio><br/>
                        <a-radio :value="'week'" class="mt-5">Starting Next week</a-radio><br/>
                        <a-radio :value="'weeks'" class="mt-5">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-right">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="applyFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
            
        </a-modal>


		<a-drawer
            title=""
            placement="right"
            :closable="true"
            :visible="filter.showModal"
            @close="onCloseDrawer"
            width="520">
            
            <a-row :gutter="[24, 24]" class="mt-20 pt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <a-input-search
						v-model="filter.search"
						placeholder="Search...."
						size="large"
						@search="submitSearchFilter"
						style="width: 90%"/>
					<a-button id="textCloseBtn" type="text" class="text-danger" v-if="filter.search.length > 0" @click="clearSearch">
						<font-awesome-icon icon="fa-solid fa-close" class="" style=""/>
					</a-button>
                </a-col>
            </a-row>

            
            <a-row :gutter="[24, 24]" class="mt-20 pt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 16px; color: #8C8C8C !important;" class="mb-5 pb-0">Refine results</h6>

					<hr style="border: 0.5px solid #E4E4E4 !important;"/>
                </a-col>
            </a-row>

            <a-row :gutter="[24, 24]" class="mt-15 mb-0 pb-0" v-if="$route.name != 'Tutors' && $route.name != 'Quizzes' && $route.name != 'Classes' && $route.name != 'Notes'">
                <a-col :span="24" class="">
                    <label class="" style="color: #606174 !important;"><strong style="color: #606174 !important;">Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'" class="">Classes</a-radio><br/>
                        <a-radio :value="'notes'" class="mt-5">Notes</a-radio><br/>
                        <a-radio :value="'quizzes'" class="mt-5">Quizzes</a-radio><br/>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutter="[24, 24]" class="mt-15 mb-0 pb-0" v-if="$route.name != 'Tutors'">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-5 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-5 mb-0 pb-0" v-if="$route.name != 'Tutors' && $route.name != 'Quizzes' && $route.name != 'Notes'">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'" class="">Starting Today</a-radio><br/>
                        <a-radio :value="'week'" class="mt-5">Starting Next week</a-radio><br/>
                        <a-radio :value="'weeks'" class="mt-5">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-center">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="applyFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
        </a-drawer>

	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->

</template>

<script>

	// import BreadCrumbs from './BreadCrumbs.vue';
import { notification } from 'ant-design-vue';
	export default ({
		components: {
			// BreadCrumbs
		},
		data() {
			return {
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				windowWidth: window.innerWidth,
				collapseNav: 0,
				userDetails: {},
				role: {},
				currentRoute: null,

				filter: {
					search: '',
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: 'classes',
                    price: 0,
                    tutorRating: 0,
                    startTime: null,
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

				search: '',
			}
		},
		created() {
			this.getUserDetails();
		}, 
		mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
        computed: {
            getCurrentRoute () {
                return $root.$refs.Classes == 'Classes'
            },
        },
		methods: {

			onResize() {
				this.windowWidth = window.innerWidth
			},

			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.userDetails = userDetails

					this.role = userDetails.role

					console.log(this.role)

				}
				
			},


			toProfilePage() {
				this.$router.push('/profile')
			},


			async handleLogout(e) {
				await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)
				await localStorage.setItem("user_token", null)

				this.$router.push(`/sign-in`);
			},

			launchSearchFilter() {
				this.filter.showModal = true;
			},

			applyFilter() {
				this.filter.isActive = true;
				this.submitSearchFilter();
			},


			clearSearch() {
				
				this.filter.search = '',

				this.submitSearchFilter();
			},

			onCloseDrawer() {
				this.filter.showModal = false
			},


			clearFilter() {
				
				this.filter.loading = false,
				this.filter.showModal = false,
				this.filter.isActive = false,
				this.filter.type = 'classes',
				this.filter.price = 0,
				this.filter.tutorRating = 0,
				this.filter.startTime = null,

				this.submitSearchFilter();
			},


			submitSearchFilter() {

				if(this.$route.name == 'Home' && this.role.code == 'student') {
					this.filter.showModal = false;
					this.$root.$refs.StudentHome.submitSearchFilter(this.filter);
				}
				else if(this.$route.name == 'Tutors') {
					this.filter.showModal = false;
					this.$root.$refs.Tutors.submitSearchFilter(this.filter);
				}else{

					if(this.filter.type == 'classes') {
						this.filter.showModal = false;
						this.$root.$refs.Classes.submitSearchFilter(this.filter);
					}

					else if(this.filter.type == 'notes') {
						this.filter.showModal = false;
						this.$root.$refs.Notes.submitSearchFilter(this.filter);
					}

					else if(this.filter.type == 'quizzes') {
						this.filter.showModal = false;
						this.$root.$refs.Quizzes.submitSearchFilter(this.filter);
					}
					
				}
			},

		}
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}
	#textCloseBtn {
		background: #FFFFFF;
		color: #F5222D;
		border: none;
		box-shadow: none;
		font-size: 16px;
	}

</style>