<template>
	<a-breadcrumb class="py-15">
		<a-breadcrumb-item v-if="breadcrumbs.length == 0">Home</a-breadcrumb-item>
		<a-breadcrumb-item v-if="breadcrumbs.length > 0">
			<a href="/home">Home</a>
		</a-breadcrumb-item>
		<a-breadcrumb-item  v-for="(breadcrumb, index) in breadcrumbs" :key="index">
			<a :href="breadcrumb.url" v-if="!breadcrumb.isActive">{{ breadcrumb.title }}</a>
			<span v-if="breadcrumb.isActive">{{ breadcrumb.title }}</span>
		</a-breadcrumb-item>
  	</a-breadcrumb>
</template>

<script>
	export default ({
		data() {
			return {
				breadcrumbs: [],
			}
		},
		// watch:{
		// 	$route (to, from){
		// 		setTimeout(() => this.getBreadCrumbs(),150);
		// 	}
		// },
		created() {
			this.$root.$refs.Breadcrumbs = this;
			// this.getBreadCrumbs();
		},
		methods: {
			async initializeBreadCrumbs(breadcrumbs) {

				this.breadcrumbs = breadcrumbs;

				// let breadcrumbs = await localStorage.getItem("breadcrumbs")

				// if(breadcrumbs != null) {
				// 	this.breadcrumbs = JSON.parse(breadcrumbs);

				// }

			},

			// async getBreadCrumbs(breadcrumbs) {

			// 	this.breadcrumbs = breadcrumbs;

				// let breadcrumbs = await localStorage.getItem("breadcrumbs")

				// if(breadcrumbs != null) {
				// 	this.breadcrumbs = JSON.parse(breadcrumbs);

				// }

			// },
        }
	})

</script>
