import axios from "axios";

let token = localStorage.getItem("user_token")

// if(!token) {
//     this.$router.push(`/login`);
// }

const Axios = axios.create({
    // baseURL: "http://localhost:3000/api/v1",
    baseURL: "https://dev.silabu.com/v1",
    // baseURL: "https://staging.silabu.com/v1",
});

Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default Axios;